<template>
  <div>
    <page-title :heading="$t('analytics.lang_salesPerTime')" :icon="icon"
                :subheading="$t('analytics.lang_salesPerTime')"></page-title>
    <div class="app-main__inner">
      <sales-per-time-graph-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import SalesPerTimeGraphComponent from "@/components/analytics/warewanlytics/sales/SalesPerTimeStatisticsComponent";

export default {
  name: "index",
  components: {SalesPerTimeGraphComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>